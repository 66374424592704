import './App.css';
import PostList from './Components/PostList';

function App() {
  return (
    <div>
      <h1>Stefanus Cahya Abadi</h1>
      <PostList/>
    </div>
  );
}

export default App;
